var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBarCollapsed),expression:"!sideBarCollapsed"}],staticClass:"capacitiesDashboardSideBar",staticStyle:{"background-color":"rgb(241, 241, 242)","display":"flex","flex-direction":"column"},style:({ width: _vm.width + 'px', minWidth: _vm.width + 'px' })},[_c('div',{staticClass:"pt-3 pl-3 pr-3 pb-1",staticStyle:{"display":"flex"}},[_c('i',{staticClass:"fas fa-chevron-left",staticStyle:{"font-size":"2em","color":"#cacaca","margin-left":"auto","cursor":"pointer"},on:{"click":function($event){return _vm.closeSideBar()}}})]),_c('div',{staticClass:"pt-0 pb-3",staticStyle:{"display":"flex","flex-direction":"column","flex":"1 1 0","min-height":"0","height":"100%","padding-left":"16px","padding-right":"16px"}},[_c('div',{staticClass:"mb-3 buttonToggleDashboardView"},[_c('b-button',{staticClass:"mr-1 calendarViewBtn",attrs:{"pressed":_vm.value.filters.intervalType == 'days',"size":"sm","variant":"primary"},on:{"click":function () {
            _vm.updateSideBarData('filters.intervalType', 'days');
            _vm.saveSelectedIntervalTypeForDashboard('days');
          }}},[_vm._v(_vm._s(_vm.$t("general.days")))]),_c('b-button',{staticClass:"mr-1 calendarViewBtn",attrs:{"pressed":_vm.value.filters.intervalType == 'weeks',"size":"sm","variant":"primary"},on:{"click":function () {
            _vm.updateSideBarData('filters.intervalType', 'weeks');
            _vm.saveSelectedIntervalTypeForDashboard('weeks');
          }}},[_vm._v(_vm._s(_vm.$t("general.weeks")))]),_c('b-button',{staticClass:"mr-1 calendarViewBtn",attrs:{"pressed":_vm.value.filters.intervalType == 'months',"size":"sm","variant":"primary"},on:{"click":function () {
            _vm.updateSideBarData('filters.intervalType', 'months');
            _vm.saveSelectedIntervalTypeForDashboard('months');
          }}},[_vm._v(_vm._s(_vm.$t("general.months")))])],1),_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("general.dateRange")))]),_c('date-picker',{attrs:{"value":_vm.value.filters.dateRange,"placeholder":'',"formatter":{
          stringify: function (date) {
            return date ? _vm.$dayjs(date).format('L') : '';
          },
        },"range":"","popup-style":{ zIndex: 9999 }},on:{"input":function (e) {
            _vm.updateSideBarData('filters.dateRange', e);
            _vm.saveSelectedDateRangeForDashboard(e);
          }}})],1),_c('b-form-group',{staticClass:"mb-2"},[_c('label',[_vm._v(_vm._s(_vm.$t("general.workOrderType")))]),_c('multiselect',{attrs:{"value":_vm.value.filters.workOrderTypes,"options":_vm.workOrderTypes,"show-labels":false,"label":"name","track-by":"idPublic","multiple":"","placeholder":''},on:{"input":function (e) {
            _vm.updateSideBarData('filters.workOrderTypes', e);
            _vm.saveSelectedWorkOrderTypesForDashboard(e);
          }}})],1),_c('hr',{staticStyle:{"margin-top":"10px","margin-bottom":"10px","width":"100%"}}),_c('div',{staticClass:"mb-3 mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("general.dashboards")))]),(_vm.userIsAdmin)?_c('i',{staticClass:"bx bxs-plus-square defaultIconBtn",staticStyle:{"color":"#c1c7dd","right":"-3px","position":"relative","cursor":"pointer","font-size":"2.5em","margin-left":"auto"},on:{"click":function($event){_vm.openCreateCapacityDashboard = true}}}):_vm._e()]),_c('Simplebar',{staticStyle:{"margin-right":"-10px","flex":"1 1 0","min-height":"0"},attrs:{"lass":"capacityDashboardsItems"}},_vm._l((_vm.dashboards),function(dash,index){return _c('div',{key:index,staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"mb-2",staticStyle:{"display":"flex","align-items":"center"}},[_c('b-button',{class:{
              activeCapacityViewBtn: dash.active == true,
              capacityViewBtn: !dash.active == true,
              'mr-2': !_vm.userIsAdmin,
              'mr-1': _vm.userIsAdmin,
            },staticStyle:{"flex":"1 1 0","text-align":"left","background":"#bcbec08f","border-color":"#adadad"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.selectDashboard(dash)}}},[_vm._v(" "+_vm._s(dash.name)+" ")]),(_vm.userIsAdmin)?_c('div',{staticStyle:{"margin-left":"auto","display":"flex","align-items":"center"}},[(dash.active == true)?_c('b-button',{staticClass:"mr-1",staticStyle:{"background":"rgba(188, 190, 192, 0.18)","border":"none","color":"white"},attrs:{"variant":"primary"},on:{"click":function($event){_vm.addCapacityChartModalOpen = true}}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"position":"relative","top":"1px","cursor":"pointer","font-size":"1.2em","color":"#556ee6"}})]):_vm._e(),_c('b-button',{staticStyle:{"margin-right":"10px","background":"rgba(188, 190, 192, 0.18)","border":"none"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openRemoveDashboardModal(dash)}}},[_c('i',{staticClass:"fas fa-trash-alt",staticStyle:{"cursor":"pointer","color":"rgba(160, 160, 160, 0.62)","font-size":"1.2em"}})])],1):_vm._e()],1)])}),0)],1),_c('DeletionModal',{ref:"dashboardDeleteModal",attrs:{"content":this.$t('general.deleteCapacityDashboardText'),"title":this.$t('general.deleteCapacityDashboardTitle')}}),_c('AddCapacityChartModal',{attrs:{"dashboardId":_vm.getSelectedDashboardId},on:{"capacity-chart-created":_vm.capacityChartCreated},model:{value:(_vm.addCapacityChartModalOpen),callback:function ($$v) {_vm.addCapacityChartModalOpen=$$v},expression:"addCapacityChartModalOpen"}}),_c('CreateCapacityDashboardModal',{on:{"created-capacity-dashboard":_vm.loadCapacityDashboards},model:{value:(_vm.openCreateCapacityDashboard),callback:function ($$v) {_vm.openCreateCapacityDashboard=$$v},expression:"openCreateCapacityDashboard"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }