<script>
import CalendarApiService from "@/services/calendarService";
import Simplebar from "simplebar-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { cloneDeep, tap, set } from "lodash";
import UserConfigMixin from "@/mixins/userConfigMixin";
import EventBus from "@/router/views/dashboards/capacities/eventBus/eventBus";
import CreateCapacityDashboardModal from "@/router/views/dashboards/capacities/components/modals/createCapacityDashboardModal";
import AddCapacityChartModal from "@/router/views/dashboards/capacities/components/modals/addCapacityChartModal";
import DeletionModal from "../../../../../components/widgets/deletion-modal.vue";

/**
 * Dashboard Component
 */
export default {
  mixins: [UserConfigMixin],
  props: ["sideBarCollapsed", "value"],
  page: {},
  components: {
    Simplebar,
    DatePicker,
    CreateCapacityDashboardModal,
    AddCapacityChartModal,
    DeletionModal,
  },
  data() {
    return {
      width: 300,
      filtersContainerOpened: true,
      openCreateCapacityDashboard: false,
      showConfirmDeleteCapacityDashboardLoading: false,
      addCapacityChartModalOpen: false,
      workOrderTypes: [],
      selectedDashboard: null,
      dashboards: [],
      selectedDashboardToDelete: null,
    };
  },
  async created() {
    this.$bus.$on("locale-updated", this.localeUpdated);

    //Load capacity dashboards
    await this.loadCapacityDashboards();

    let response2 = await CalendarApiService.getAllJobTypes();
    this.workOrderTypes = response2.data.data;
  },
  beforeDestroy() {
    this.$bus.$off("locale-updated", this.localeUpdated);
  },
  mounted() {},
  methods: {
    async loadCapacityDashboards() {
      let response = await CalendarApiService.getCapacityDashboards();

      this.dashboards = response.data.data;

      this.dashboards.forEach((x) => {
        this.$set(x, "active", false);
      });
    },
    capacityChartCreated() {
      EventBus.$emit("chart-added-to-dashboard");
    },
    localeUpdated() {
      this.$forceUpdate();
    },

    closeSideBar() {
      this.$emit("close-sidebar");
    },
    updateSideBarData(key, value) {
      let copy = tap(cloneDeep(this.value), (v) => {
        set(v, key, value);
      });

      this.$emit("input", copy);
    },
    refreshContent() {
      EventBus.$emit("reload-capacities-chart");
    },
    log(e) {
      window.console.log(e);
    },
    saveSelectedWorkOrderTypesForDashboard(value) {
      //sauvergarde de la config pour le current selected dashboard
      let key = this.getWorkOrderTypesKey();
      localStorage.setItem(key, JSON.stringify(value));
    },
    getWorkOrderTypesKey() {
      let dashId = this.getSelectedDashboardId;
      let key = "capacities.workOrderTypes" + "." + dashId;
      return key;
    },
    getIntervalTypeKey() {
      let dashId = this.getSelectedDashboardId;
      let key = "capacities.intervalType" + "." + dashId;
      return key;
    },
    saveSelectedIntervalTypeForDashboard(type) {
      let key = this.getIntervalTypeKey();
      localStorage.setItem(key, type);
    },
    saveSelectedDateRangeForDashboard(dateRange) {
      //sauvergarde de la config pour le current selected dashboard
      let dateRangeKey = this.getDateRangeKey();
      localStorage.setItem(dateRangeKey, JSON.stringify(dateRange));
    },
    getDateRangeKey() {
      let dashId = this.getSelectedDashboardId;
      let key = "capacities.dateRange." + dashId;
      return key;
    },
    // async removeDashboard() {
    //   let id = this.selectedDashboardToDelete.id;

    //   this.showConfirmDeleteCapacityDashboardLoading = true;
    //   await CalendarApiService.removeCapacityDashboard(id);
    //   this.showConfirmDeleteCapacityDashboardLoading = false;

    //   let index = this.dashboards.findIndex((x) => {
    //     return x.id == id;
    //   });
    //   this.dashboards.splice(index, 1);

    //   this.selectedDashboardToDelete = null;

    //   this.loadCapacityDashboards();
    // },
    openRemoveDashboardModal(dash) {
      this.selectedDashboardToDelete = dash;
      this.$refs.dashboardDeleteModal.show().then(async () => {
        let id = this.selectedDashboardToDelete.id;

        this.showConfirmDeleteCapacityDashboardLoading = true;
        await CalendarApiService.removeCapacityDashboard(id);
        this.showConfirmDeleteCapacityDashboardLoading = false;

        let index = this.dashboards.findIndex((x) => {
          return x.id == id;
        });
        this.dashboards.splice(index, 1);

        this.selectedDashboardToDelete = null;

        this.loadCapacityDashboards();
      });
    },
    loadFiltersForDashboard() {
      //=====INTERVAL TYPE=====
      //si nexsite pas, save current config for dahsboard
      let intervalTypeKey = this.getIntervalTypeKey();
      let intervalTypeSaved = localStorage.getItem(intervalTypeKey);
      let intervalType = null;
      if (intervalTypeSaved == undefined) {
        //save current config for dashboard
        this.saveSelectedIntervalTypeForDashboard(
          this.value.filters.intervalType
        );
        intervalType = this.value.filters.intervalType;
      } else {
        intervalType = intervalTypeSaved;
      }

      //=====DATE RANGE=====
      let dateRangeKey = this.getDateRangeKey();
      let dateRangeSaved = localStorage.getItem(dateRangeKey);
      let dateRange = null;
      if (dateRangeSaved == undefined) {
        //save current config for dashboard
        this.saveSelectedDateRangeForDashboard(this.value.filters.dateRange);
        dateRange = this.value.filters.dateRange;
      } else {
        dateRange = JSON.parse(dateRangeSaved);
        dateRange[0] = new Date(dateRange[0]);
        dateRange[1] = new Date(dateRange[1]);
      }

      //=====WORK ORDER TYPES=====
      let workOrderTypesKey = this.getWorkOrderTypesKey();
      let workOrderTypesSaved = localStorage.getItem(workOrderTypesKey);
      let workOrderTypes = null;
      if (workOrderTypesSaved == undefined) {
        this.saveSelectedWorkOrderTypesForDashboard(
          this.value.filters.workOrderTypes
        );
        workOrderTypes = this.value.filters.workOrderTypes;
      } else {
        workOrderTypes = JSON.parse(workOrderTypesSaved);
      }

      //Update vmodel(emit new data)
      let filters = {
        intervalType: intervalType,
        dateRange: dateRange,
        workOrderTypes: workOrderTypes,
      };
      this.updateSideBarData("filters", filters);
    },
    selectDashboard(dash) {
      //enlever dashboard pour que le changement de filters ne trigger pas de reload de charts
      EventBus.$emit("selected-dashboard", null);

      this.dashboards.forEach((x) => {
        x.active = false;
      });
      dash.active = true;

      this.selectedDashboard = dash;

      //load filters for current selected dashboard
      this.loadFiltersForDashboard();

      //Load selected dashboard after changing filters pour ne pas loader 2x le data
      //send event for content component
      EventBus.$emit("selected-dashboard", dash);
    },
  },
  computed: {
    getSelectedDashboardId() {
      return this.selectedDashboard?.id;
    },
    visibleInstallers() {
      let visibleInstallers = [];
      for (var installer of this.value.installers) {
        if (this.userCanSeeInstaller(installer)) {
          let copy = cloneDeep(installer);
          visibleInstallers.push(copy);
        }
      }

      return visibleInstallers;
    },
    sortedVisibleProgress() {
      let visibleProgress = [];
      for (var progress of this.value.progress) {
        if (this.userCanSeeProgress(progress)) {
          let copy = cloneDeep(progress);
          visibleProgress.push(copy);
        }
      }

      return visibleProgress.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );
    },
    deleteCapacityDashboardTitle() {
      return (
        this.$t("general.deleteCapacityDashboardTitle") +
        " " +
        this.selectedDashboardToDelete?.name
      );
    },
  },
};
</script>

<template>
  <div
    v-show="!sideBarCollapsed"
    :style="{ width: width + 'px', minWidth: width + 'px' }"
    class="capacitiesDashboardSideBar"
    style="
      background-color: rgb(241, 241, 242);
      display: flex;
      flex-direction: column;
    "
  >
    <div style="display: flex" class="pt-3 pl-3 pr-3 pb-1">
      <i
        @click="closeSideBar()"
        style="
          font-size: 2em;
          color: #cacaca;
          margin-left: auto;
          cursor: pointer;
        "
        class="fas fa-chevron-left"
      ></i>
    </div>
    <div
      class="pt-0 pb-3"
      style="
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        min-height: 0;
        height: 100%;
        padding-left: 16px;
        padding-right: 16px;
      "
    >
      <div class="mb-3 buttonToggleDashboardView">
        <b-button
          :pressed="value.filters.intervalType == 'days'"
          @click="
            () => {
              updateSideBarData('filters.intervalType', 'days');
              saveSelectedIntervalTypeForDashboard('days');
            }
          "
          size="sm"
          variant="primary"
          class="mr-1 calendarViewBtn"
          >{{ $t("general.days") }}</b-button
        >
        <b-button
          :pressed="value.filters.intervalType == 'weeks'"
          @click="
            () => {
              updateSideBarData('filters.intervalType', 'weeks');
              saveSelectedIntervalTypeForDashboard('weeks');
            }
          "
          size="sm"
          variant="primary"
          class="mr-1 calendarViewBtn"
          >{{ $t("general.weeks") }}</b-button
        >
        <b-button
          :pressed="value.filters.intervalType == 'months'"
          @click="
            () => {
              updateSideBarData('filters.intervalType', 'months');
              saveSelectedIntervalTypeForDashboard('months');
            }
          "
          size="sm"
          variant="primary"
          class="mr-1 calendarViewBtn"
          >{{ $t("general.months") }}</b-button
        >
      </div>
      <div class="mb-1">
        <label>{{ $t("general.dateRange") }}</label>
        <date-picker
          :value="value.filters.dateRange"
          @input="
            (e) => {
              updateSideBarData('filters.dateRange', e);
              saveSelectedDateRangeForDashboard(e);
            }
          "
          :placeholder="''"
          :formatter="{
            stringify: (date) => {
              return date ? $dayjs(date).format('L') : '';
            },
          }"
          range
          :popup-style="{ zIndex: 9999 }"
        ></date-picker>
      </div>

      <b-form-group class="mb-2">
        <label>{{ $t("general.workOrderType") }}</label>
        <multiselect
          :value="value.filters.workOrderTypes"
          @input="
            (e) => {
              updateSideBarData('filters.workOrderTypes', e);
              saveSelectedWorkOrderTypesForDashboard(e);
            }
          "
          :options="workOrderTypes"
          :show-labels="false"
          label="name"
          track-by="idPublic"
          multiple
          :placeholder="''"
        >
        </multiselect>
      </b-form-group>
      <hr style="margin-top: 10px; margin-bottom: 10px; width: 100%" />
      <div class="mb-3 mt-3" style="display: flex; align-items: center">
        <h5 class="mb-0">{{ $t("general.dashboards") }}</h5>
        <i
          v-if="userIsAdmin"
          @click="openCreateCapacityDashboard = true"
          style="
            color: #c1c7dd;
            right: -3px;
            position: relative;
            cursor: pointer;
            font-size: 2.5em;
            margin-left: auto;
          "
          class="bx bxs-plus-square defaultIconBtn"
        ></i>
      </div>

      <Simplebar
        lass="capacityDashboardsItems"
        style="margin-right: -10px; flex: 1 1 0; min-height: 0"
      >
        <div
          style="cursor: pointer"
          v-for="(dash, index) in dashboards"
          :key="index"
        >
          <div class="mb-2" style="display: flex; align-items: center">
            <b-button
              @click="selectDashboard(dash)"
              :class="{
                activeCapacityViewBtn: dash.active == true,
                capacityViewBtn: !dash.active == true,
                'mr-2': !userIsAdmin,
                'mr-1': userIsAdmin,
              }"
              style="
                flex: 1 1 0;
                text-align: left;
                background: #bcbec08f;
                border-color: #adadad;
              "
              variant="primary"
            >
              {{ dash.name }}
            </b-button>

            <div
              v-if="userIsAdmin"
              style="margin-left: auto; display: flex; align-items: center"
            >
              <b-button
                v-if="dash.active == true"
                @click="addCapacityChartModalOpen = true"
                class="mr-1"
                variant="primary"
                style="
                  background: rgba(188, 190, 192, 0.18);
                  border: none;
                  color: white;
                "
              >
                <i
                  style="
                    position: relative;
                    top: 1px;
                    cursor: pointer;
                    font-size: 1.2em;
                    color: #556ee6;
                  "
                  class="fas fa-plus-circle"
                ></i>
              </b-button>
              <b-button
                @click="openRemoveDashboardModal(dash)"
                variant="primary"
                style="
                  margin-right: 10px;
                  background: rgba(188, 190, 192, 0.18);
                  border: none;
                "
              >
                <i
                  style="
                    cursor: pointer;
                    color: white;
                    font-size: 1.2em;
                    color: rgba(160, 160, 160, 0.62);
                  "
                  class="fas fa-trash-alt"
                ></i>
              </b-button>
            </div>
          </div>
        </div>
      </Simplebar>
    </div>

    <DeletionModal
      ref="dashboardDeleteModal"
      :content="this.$t('general.deleteCapacityDashboardText')"
      :title="this.$t('general.deleteCapacityDashboardTitle')"
    />

    <AddCapacityChartModal
      v-model="addCapacityChartModalOpen"
      :dashboardId="getSelectedDashboardId"
      @capacity-chart-created="capacityChartCreated"
    ></AddCapacityChartModal>
    <CreateCapacityDashboardModal
      v-model="openCreateCapacityDashboard"
      @created-capacity-dashboard="loadCapacityDashboards"
    ></CreateCapacityDashboardModal>
  </div>
</template>
